<template>
  <div class="main">
    <el-backtop></el-backtop>
    <!-- header -->
    <div class="header">
      <div class="imagebox">
        <img src="../assets/img/ccbst.png" alt="" />
      </div>
      <div class="header">
        <div class="imagebox">
          <img src="../assets/img/ccbst.png" alt="" />
        </div>
        <div class="navbox">
          <el-menu
            :default-active="onRoutes"
            class="el-menu-demo"
            active-text-color="#3FBCBB"
            mode="horizontal"
            @select="handleSelect"
            router
          >
            <el-menu-item
              v-for="item in navlist"
              :key="item.id"
              :index="item.path"
              >{{ item.names }}
            </el-menu-item>
          </el-menu>
        </div>
        <div class="loginbox" v-if="islogin">
             <div class="newsNumbox" @click="getnews">
          <img src="../assets/img/zhong.png" alt="" />
           <span v-if="UserObj.NoReadCount&&UserObj.NoReadCount<99" class="red">{{UserObj.NoReadCount}}</span>
          <span v-if="UserObj.NoReadCount>=99" class="red">99</span>
        </div>
          <el-button  @click="getuserCenter">个人中心</el-button>
          <el-button  @click="getLoginOut">退出登录</el-button>
        </div>
        <div class="loginbox" v-else>
          <el-button  @click="getlogin">登录</el-button>
          <el-button  @click="register">注册</el-button>
        </div>
        <div class="contanbox">
          <img
            @click="drawer = !drawer"
            src="../assets/img/contans.png"
            alt=""
          />
        </div>
      </div>
   
   

    </div>

    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">
      <el-drawer
        title="西安浐灞产业联盟"
        :visible.sync="drawer"
        :with-header="false"
        direction="ltr"
        size="50%"
        style="padding-top: 80px; box-sizing: border-box"
      >
        <div class="loginboxs" v-if="!islogin">
          <el-button  size="mini" @click="getlogin"
            >登录</el-button
          >
          <el-button  size="mini" @click="register"
            >注册</el-button
          >
        </div>

        <div class="loginboxs" v-else>
          <el-button  size="mini" @click="getuserCenter"
            >个人中心</el-button
          >
          <el-button  size="mini" @click="getLoginOut">退出登录</el-button>
        </div>
        <div class="minnav">
          <el-row class="tac">
            <el-col :span="12" style="width: 100%">
              <el-menu
                :default-active="onRoutes"
                class="el-menu-vertical-demo"
                @select="handleSelect"
                background-color="#fff"
                text-color="#000"
                active-text-color="#53C3C2"
                router
              >
                <el-menu-item
                  style="padding: 0 10px"
                  v-for="item in navlist"
                  :key="item.id"
                  :index="item.path"
                 
                >
                  <i class="el-icon-menu"></i>
                  <span slot="title">{{ item.names }}</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </el-drawer>

      <router-view> </router-view>

      <!-- body end -->
      <!-- foot start -->
      <div class="footbox">
        <div class="foot_left">
          <div class="left_top">快速进入</div>
          <div class="left_bottom">
            <span
              v-for="(item, i) in navlist"
              :key="i"
              @click="handleSelectFoot(item)"
              >{{ item.names }}<i class="el-icon-arrow-right"></i
            ></span>
          </div>
        </div>
        <div class="foot_right">
          <p>
            Copyright © 2021陕西省西安浐灞国际会展有限责任公司. All Rights
            Reserved
          </p>
          <p>
            <a style="color:#717071" target="_blank" rel="noopener" href="http://beian.miit.gov.cn/">陕ICP备15005823号-2</a>
          </p>
          <p>联系电话：029-83592139、029-83592506</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 公共样式

import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      UserObj:{},
      islogin: window.localStorage.getItem("userId"),
      height: "",
      activeName: "-1",
      NewsList: [],
      CateList: [],
      InteractionGList: [],
      InteractionXList: [],
      BuildingList: [],
      ServeList: [],
      CompanyList: [],
      NewNewsList: [],
      NewCompanyList: [],
      activeIndex: "1",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/PolicyInformation",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        // {
        //   names: "企业互动",
        //   path: "/interaction",
        //   id: "4",
        // },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/serverType",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
        {
          names: "自贸专栏",
          path: "/freeTrade",
          id: "8",
        },
        {
          names: "会展信息",
          path: "/Xdetile",
          id: "9",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
    };
  },

  computed: {
    onRoutes() {
      let that = this;
      let path = that.$route.path.replace("/index", "");
       if (path=='/interaction'||path=='/Gdetile') {
        path='/serverType'
        return path
      }
      let i = 1;
      let list = that.navlist;
      list.forEach((v) => {
        if (v.path == path) {
          console.log(path);
          window.localStorage.setItem("path", path);
          return (i = 2);
        }
      });
      if (i == 2) {
        return path;
      } else {
        return window.localStorage.getItem("path");
      }
    },
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
            // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
         

          let UserObj = data.Obj.UserObj;
          if (!UserObj) {
            // window.localStorage.clear();
            // that.$router.push("/");

            return;
          }
        
          that.UserObj = UserObj;
        
        })
        .catch((error) => {
          console.log(error);
        });
    },
        getnews(){
this.$router.push('/companyCenter/newsCenter')
    },
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },
    //   服务详情
    getServeInfor(id) {
      this.$router.push({ path: "/serviceDetails", query: { SysID: id } });
    },
    //   载体详情
    GetBuildingIn(id) {
      this.$router.push({ path: "/carrierDetails", query: { SysID: id } });
    },
    getGinfor(item) {
      this.$router.push({ path: "/Gdetile", query: { SysID: item } });
    },
    getuserCenter() {
      this.$router.push("/companyCenter/personalCenter");
    },
    handleSelect() {
      let that = this 
       that.getnewsList()
    },
    handleSelectFoot(item) {
      let that = this;
       that.getnewsList()
      that.$router.push(item.path);
    },
    handleClick(e) {
      var catename = "";
      if (e != null && e.label != "最新资讯") {
        catename = e.label;
      }
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          CateName: catename,
          Sort: "CreateOn desc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewsList = data.Obj;
          that.NewsList.forEach((v) => {
            if (v.FirstImgUrl) {
              v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    InformationDetails(SysID) {
      this.$router.push({
        path: "/InformationDetails",
        query: { SysID: SysID },
      });
    },
    getLoginOut() {
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.localStorage.clear();
        this.$router.go(0);
      });
    },
    GetNewNewsList() {
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewNewsList = data.Obj;
          that.NewNewsList.forEach((v) => {
            if (v.FirstImgUrl) {
              v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCateList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "NewsCate",
          Sort: "CreateOn desc",
        })
        .then(function (data) {
          console.log(data);
          data.Obj.forEach((v) => {
            if (v.Name != "政策申报") {
              that.CateList.push(v);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getlogin() {
      this.$router.push({ path: "/login", query: { type: 1 } });
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    GetInteractionList() {
      let that = this;
      that
        .$post("/api/Interaction/List", {
          State: "true",
          CState: true,
          Type: 0,
          Sort: "CreateOn desc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          that.InteractionGList = data.Obj;
          that.InteractionGList.forEach((v) => {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });

      that
        .$post("/api/Interaction/List", {
          State: "true",
          Type: 1,
          CState: true,
          Sort: "CreateOn asc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.InteractionXList = data.Obj;
          that.InteractionXList.forEach((v) => {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetBuildingList() {
      let that = this;
      that
        .$post("/api/Building/List", {
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.BuildingList = data.Obj;

          that.BuildingList.forEach((v) => {
            if (v.FileList.length > 0) {
              v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
              if (v.FileList[0].URL) {
                v.FileList[0].URL = that.urlOA + v.FileList[0].URL.substr(1);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetServeList() {
      let that = this;
      that
        .$post("/api/Serve/List", {
          State: true,
          CState: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.ServeList = data.Obj;
          that.ServeList.forEach((v) => {
            if (v.Logo) {
              v.Logo = that.urlOA + v.Logo.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState: 1,
          Sort: "CreateOn desc",
          PageSize: 12,
          Type: 1,
          IsHome: "true",
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.CompanyList = data.Obj;
          that.CompanyList.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState: 1,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  updated() {
    window.scroll(0, 0);
  },
  mounted() {
    var that = this;
    let id = window.localStorage.getItem("index");
    if (id) {
      that.activeIndex = id;
    }

    let windowWidth = that.windowWidth;
    if (windowWidth > 900) {
      that.navlist = [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/PolicyInformation",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        // {
        //   names: "企业互动",
        //   path: "/interaction",
        //   id: "4",
        // },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/serverType",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
            {
          names: "会展专栏",
          path: "/Xdetile",
          id: "9",
        },
        {
          names: "自贸专栏",
          path: "/freeTrade",
          id: "8",
        },
    
      ];
    } else {
      that.navlist = [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/PolicyInformation",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        // {
        //   names: "企业互动",
        //   path: "/interaction",
        //   id: "4",
        // },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/serverType",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
        {
          names: "自贸专栏",
          path: "/freeTrade",
          id: "8",
        },
      ];
    }

    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
    that.GetCateList();
    that.handleClick();
    that.GetInteractionList();
    this.GetBuildingList();
    that.GetServeList();
    that.GetCompanyList();
    that.GetNewCompanyList();
    that.GetNewNewsList();
    that.getnewsList()
  },
};
</script>


<style scoped  lang="scss">

.el-menu--horizontal>.el-menu-item{
  color: rgba(0, 0 ,0 ,.7);
}
.el-button{
  border: none;
}
.newsNumbox {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  cursor: pointer;
  img {
    height: 60%;
    // margin-top: 10%;
    width: 60%;
    margin-left: 10%;
    vertical-align: middle;
  }
  .red {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: red;
    border-radius: 50%;
    right: 10px;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }
}
.loginboxs::v-deep .el-button--mini{
  padding: 7px 10px;
}
#accordionColumn1 {
  float: left;
  width: 48%;
}
#accordionColumn2 {
  float: right;
  width: 50%;
}

.sr-card {
  float: left;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  border-radius: 3px;
  box-shadow: 0 2px 3px #ddd;
  width: 276px;
  margin-left: 34px;
  margin-top: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  .sr-card-image {
    height: 154px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .sr-card-contents {
    background-color: #fcfcfc;
    padding: 8px;
    h3 {
      height: 48px;
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
    }
  }
  .sr-card-content {
    height: 142px;
    padding: 8px;
    h3 {
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
    }
    .tags {
      line-height: 22px;
      font-size: 14px;
      overflow: hidden;
      color: #ff6633;
      margin-bottom: 8px;
      span {
        padding-right: 6px;
        height: 20px;
        font-size: 12px;
      }
    }
    p {
      color: #666;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .sr-card-footer {
    background-color: #f3f7fb;
    padding: 8px;

    .fl {
      float: left;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #f8f8f8;
    }
    .company {
      margin-top: 10px;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-items: center;
      line-height: 20px;
      padding-left: 10px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.sr-card:hover {
  box-shadow: 0 6px 40px #ddd;
}
.sr-row {
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: 30px;
}
.sr-row:before,
.sr-row:after {
  content: "";
  display: table;
  clear: both;
}
.row_item {
  float: left;
  cursor: pointer;
  display: block;
  width: 172px;
  height: 100px;
  background-color: #f9f9f9;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  margin-left: 36px;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
// 内容区
.textleft::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 18px;
  font-weight: 800;
  color: #303133;
  position: relative;
}
.textleft::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
.textleft::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
// banner
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__container {
  // height: 200px;
  height: 1000px !important;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  padding-top: 30px;
  overflow: hidden;
}
.textleft {
  float: left;
  width: 900px;
}
.textright {
  float: left;
  width: 300px;
  // height: 600px;
  margin-left: 20px;
  h4 {
    border-left: 4px solid #3273dc;

    padding-left: 10px;
  }
}
.item {
  position: relative;
  height: 160px;
  width: 100%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 160px;
    width: 280px;
  }
  .newsbox {
    position: absolute;
    left: 310px;
    height: 100%;
    width: 550px;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      top: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    p {
      position: absolute;
      top: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;

      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
.items {
  font-size: 0.8rem;
  line-height: 36px;
  height: 36px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    line-height: 32px;
    height: 32px;
  }
}
.items:hover,
.item h3:hover,
.item p:hover {
  color: #2b60a5;
}
// 底部
.footbox {
  display: flex;
  justify-content: center;
  align-items: center;

  // height: 300px;
  width: 100%;
  background-color: #343130;
}
.foot_left {
  width: 400px;
  // height: 200px;
  // background-color: #eee;
  .left_top {
    float: left;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    color: #717071;
    box-sizing: border-box;
  }
  .left_bottom span {
    color: #717071;
    float: left;
    cursor: pointer;
    width: 50%;
    height: 40px;
    line-height: 40px;
  }
  .left_bottom span :hover {
    color: #fff !important;
  }
}
.foot_right {
  padding-top: 60px;
  box-sizing: border-box;
  width: 600px;
  height: 200px;
  // background-color: #eee;
  p {
    color: #717071;
    // height: 40px;
    line-height: 30px;
  }
}
.aaa {
  display: none;
}
.bannerbox img {
  height: 100%;
}
@media (max-width: 1201px) {
  .textbox {
    width: 940px;
  }
  .textleft,
  .textright {
    float: left;
    width: 100%;
    overflow: hidden;
    margin: 0;
    h4 {
      text-align: center;
    }
  }
  .row_item {
    float: left;
    display: block;
    width: 191px;
    height: 110px;

    margin-bottom: 20px;
    margin-left: 36px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 991px) {
  .el-carousel__arrow {
    top: 25% !important;
  }
  .aaa {
    display: block;
  }
  // .bannerbox {
  //   height: 200px;
  // }
  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
  .textleft,
  .textright {
    float: left;
    width: 100%;
    overflow: hidden;
    margin: 0;
    h4 {
      text-align: center;
    }
  }
  .item {
    position: relative;
    height: 120px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 120px;
      width: 30%;
    }
    .newsbox {
      position: absolute;
      left: 35%;
      height: 100%;
      width: 60%;
      padding: 6px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        // height: 40px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        position: absolute;
        height: 40px;
        line-height: 20px;
        top: 50px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        bottom: 0;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 890px) {
  .main .textbox {
    width: 650px;
  }
}
@media (max-width: 690px) {
  .textbox {
    width: 600px;
    margin-left: 50%;
    transform: translateX(-50%);
    // background-color: #475669;
    padding-top: 100px;
    overflow: hidden;
  }
  .textleft {
    float: left;
    width: 600px;
  }
  .textright {
    float: left;
    width: 600px;
    // height: 600px;
    margin-left: 20px;
  }
  .item {
    position: relative;
    height: 80px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 80px;
      width: 120px;
    }
    .newsbox {
      position: absolute;
      left: 130px;
      height: 100%;
      width: 440px;
      padding: 6px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        top: 65px;
        line-height: 20px;
        font-size: 10px;
      }
    }
  }
  .main .textbox {
    width: 600px;
  }
  .sr-card {
    width: 250px;
  }
  .row_item {
    width: 152px;
    height: 70px;
    background-color: #f9f9f9;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    margin-left: 33px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 500px) {
    .el-button--mini, .el-button--small {
    font-size: 14px;
    color: #409EFF;
    border-radius: 3px;
}
  #accordionColumn1,
  #accordionColumn2 {
    float: left;
    width: 90%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .textleft::v-deep .el-tabs__item {
    width: 20%;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
    display: inline-block;
    list-style: none;
    font-size: 12px;
    font-weight: 800;
    color: #303133;
    position: relative;
  }
  .textleft::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .textleft::v-deep .el-tabs__item.is-active {
    color: #409eff;
  }
  .main .textbox {
    width: 370px;
  }
  .textleft {
    float: left;
    width: 375px;
  }
  .textright {
    float: left;
    width: 390px;
    // height: 600px;
    // margin-left: 20px;
  }
  .box-card {
    width: 365px;
  }
  .item {
    height: 60px;
    width: 280px;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 60px;
      width: 80px;
    }
    .newsbox {
      position: absolute;
      left: 90px;
      height: 100%;
      width: 240px;
      padding: 2px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        text-overflow: -o-ellipsis-lastline;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        top: 40px;
        line-height: 20px;
        font-size: 10px;
      }
    }
  }
  .sr-card {
    width: 334px;
  }
  .row_item {
    width: 152px;
    height: 70px;
    background-color: #f9f9f9;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    margin-left: 33px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>